import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getOgImage, removeHost } from "../../utils/functions"
import { isUserLoggedIn, logOut } from "../../utils/functions"
import Login from "../../components/login"
import Register from "../../components/register"
import { navigate } from "gatsby"
import OrderStepIndyficator from "../../components/orderStepIndyficator/OrderStepIndyficator"

import "./style.scss"

const LoginPage = props => {
  const {
    pageContext: { title, seo, uri },
    location,
  } = props

  const { errorRequiredInput, haveAccount, haveAccountKopia2, myAccountLink } =
    props.pageContext.acfMyaccountLogin
  const {
    buttonLogin,
    buttonLoginFacebook,
    heading: loginHeading,
    labelUserEmail,
    labelUserPassword: loginlabelUserPassword,
    linkRestorePassword,
  } = haveAccount

  const {
    buttonBack,
    buttonCreate,
    buttonCreateAccount,
    heading: registeHeading,
    labelUserFirstName,
    labelUserLogin,
    labelUserEmail: registerLabelUserEmail,
    labelUserPassword: registelabelUserPassword,
    labelUserPhone,
    labelUserRepeatPassword,
    labelUserSecondName,
    linkBuyWithoutCreateAccount,
    textCheckboxEmail,
    textCheckboxPhone,
    textCheckboxStatute,
    textRodo,
    textUserPasswordInfo,
    textWhyCreateAccount,
    errorUserPhone,
    errorUserEmail,
    errorUserPasswordDontMatch,
    errorUserPassword,
  } = haveAccountKopia2

  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2)

  const [comeFromCart, checkComeFromCart] = useState(false)
  const [checkoutLink, setCheckoutLink] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)
  const [orderPath, setOrderPath] = useState([])

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!isEmpty(auth)) {
      setLoggedIn(true)
      if (comeFromCart) {
        navigate(removeHost(checkoutLink))
      } else {
        navigate(removeHost(myAccountLink.url))
      }
    }

    if (location.state?.cart) {
      checkComeFromCart(true)
    }
    if (location.state?.checkoutLink) {
      setCheckoutLink(location.state?.checkoutLink)
    }
    

    if (lang === "pl") {
      setOrderPath(props.pageContext.orderPathPL)
    }
    if (lang === "en") {
      setOrderPath(props.pageContext.orderPathEN)
    }
    if (lang === "de") {
      setOrderPath(props.pageContext.orderPathDE)
    }
    if (lang === "fr") {
      setOrderPath(props.pageContext.orderPathFR)
    }
  }, [loggedIn])

  const handleLogout = () => {
    logOut()
    setLoggedIn(false)
  }

  return (
    <Layout lang={lang} page="loginPage" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={title}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"loginPage"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          {comeFromCart && (
            <OrderStepIndyficator
              orderPath={orderPath.stepIndyficator}
              activeStep={1}
            />
          )}
          <div className="container container--small">
            <div
              className={`loginPage__wrapper ${
                comeFromCart ? "loginPage__padding" : ""
              }`}
            >
              <div className="loginPage__login">
                <Login
                  setLoggedIn={setLoggedIn}
                  heading={loginHeading}
                  buttonLogin={buttonLogin}
                  buttonLoginFacebook={buttonLoginFacebook}
                  labelUserEmail={labelUserEmail}
                  loginlabelUserPassword={loginlabelUserPassword}
                  linkRestorePassword={linkRestorePassword}
                  errorRequiredInput={errorRequiredInput}
                  myAccountLink={myAccountLink}
                />
              </div>
              <div className="loginPage__register">
                <Register
                  myAccountLink={myAccountLink}
                  setLoggedIn={setLoggedIn}
                  heading={registeHeading}
                  buttonBack={buttonBack}
                  buttonCreate={buttonCreate}
                  registerLabelUserEmail={registerLabelUserEmail}
                  buttonCreateAccount={buttonCreateAccount}
                  labelUserFirstName={labelUserFirstName}
                  registelabelUserPassword={registelabelUserPassword}
                  labelUserPhone={labelUserPhone}
                  labelUserLogin={labelUserLogin}
                  labelUserRepeatPassword={labelUserRepeatPassword}
                  labelUserSecondName={labelUserSecondName}
                  linkBuyWithoutCreateAccount={linkBuyWithoutCreateAccount}
                  textCheckboxEmail={textCheckboxEmail}
                  textCheckboxPhone={textCheckboxPhone}
                  textCheckboxStatute={textCheckboxStatute}
                  textRodo={textRodo}
                  textUserPasswordInfo={textUserPasswordInfo}
                  textWhyCreateAccount={textWhyCreateAccount}
                  errorRequiredInput={errorRequiredInput}
                  errorUserPhone={errorUserPhone}
                  errorUserEmail={errorUserEmail}
                  errorUserPasswordDontMatch={errorUserPasswordDontMatch}
                  errorUserPassword={errorUserPassword}
                  comeFromCart={comeFromCart}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default LoginPage
