import React, { useState } from "react"
// import cartSpinnerGif from "../../images/cart-spinner.gif";
import { sanitize, setAuth, removeHost } from "../../utils/functions"
import { isEmpty } from "lodash"
import { useMutation } from "@apollo/client"
import LOGIN from "../../mutations/login"
import { v4 } from "uuid"
import { useForm } from "react-hook-form"
import Spin from "../spin/Spin"
import { Link } from "gatsby"
import FacebookLogin from "react-facebook-login"
import FACEBOOK_LOGIN from "../../mutations/loginFacebook"

const Login = ({
  setLoggedIn,
  heading,
  buttonLogin,
  buttonLoginFacebook,
  labelUserEmail,
  loginlabelUserPassword,
  errorRequiredInput,
  linkRestorePassword,
}) => {
  const [loginFields, setLoginFields] = useState({
    username: "",
    password: "",
  })

  const [loginFacebookFields, setLoginFacebookFields] = useState({
    email: "",
    // accessToken: "",
    userID: "",
  })

  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingFacebook, setLoadingFacebook] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  // Login Mutation.
  const [login, { error: loginError }] = useMutation(LOGIN, {
    variables: {
      input: {
        clientMutationId: v4(), // Generate a unique id.,
        username: loginFields.username,
        password: loginFields.password,
      },
    },
    onCompleted: data => {
      // If error.
      setErrorMessage("")
      if (!isEmpty(loginError)) {
        setErrorMessage(loginError.graphQLErrors[0].message)
      }

      const { login } = data
      const authData = {
        authToken: login.authToken,
        user: login.user,
        customer: login.customer,
      }

      localStorage.setItem("woo-session", login.sessionToken)
      setAuth(authData)
      setLoggedIn(true)
      setLoading(false)
    },
    onError: error => {
      setLoading(false)
      if (error) {
        if (!isEmpty(error)) {
          setErrorMessage(error.graphQLErrors[0].message)
        }
      }
    },
  })

  const [loginFacebook, { error: loginFacebookError }] = useMutation(FACEBOOK_LOGIN, {
    variables: {
      input: {
        clientMutationId: v4(), // Generate a unique id.,
        email: loginFacebookFields.email,
        userID: loginFacebookFields.userID,
      },
    },
    onCompleted: data => {


      // If error.
      setErrorMessage("")
      if (!isEmpty(loginFacebookError)) {
        setErrorMessage(loginFacebookError.graphQLErrors[0].message)
      }

      const { facebookLoginCustom } = data
      const authData = {
        authToken: facebookLoginCustom.authToken,
        user: facebookLoginCustom.user,
        customer: facebookLoginCustom.customer,
      }

      localStorage.setItem("woo-session", facebookLoginCustom.sessionToken)
      setAuth(authData)
      setLoggedIn(true)
      setLoadingFacebook(false)
    },
    onError: loginFacebookError => {

      setLoading(false)
      if (loginFacebookError) {
        if (!isEmpty(loginFacebookError)) {
          setErrorMessage(loginFacebookError.graphQLErrors[0].message)
        }
      }
    },
  })

  const onSubmit = (values, e) => {
    if (loading) return
    setLoginFields({
      username: values.username,
      password: values.password,
    })
    setLoading(true)
    login()
    setErrorMessage("")
  }

  const responseFacebook = response => {
    console.log(response)
    // connected
    // not_authorized
    setLoadingFacebook(true)
    setLoginFacebookFields({
      email: response.email,
      // accessToken: response.accessToken,
      userID: response.userID,
    })
    loginFacebook()
  }

  return (
    <div className="login__form col-md-6">
      <h2 className="loginPage__heading heading--normal heading--dash">
        {heading}
      </h2>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__input">
          <label className="form__label" htmlFor="username">
            {labelUserEmail}
            <span className="form__label__required">*</span>
          </label>
          <input
            id="username"
            type="text"
            {...register("username", {
              required: true,
            })}
          />
          <span className="form__error">
            {errors.username && errorRequiredInput}
          </span>
        </div>

        <div className="form__input">
          <label className="form__label" htmlFor="password">
            {loginlabelUserPassword}
            <span className="form__label__required">*</span>
          </label>
          <input
            id="password"
            type="password"
            {...register("password", {
              required: true,
            })}
          />
          <span className="form__error">
            {errors.password && errorRequiredInput}
          </span>
        </div>
        <div className="loginPage__link">
          <Link to={removeHost(linkRestorePassword.url)}>
            {linkRestorePassword.title}
          </Link>
        </div>
        <div className="loginPage__button form__button">
          <button type="submit">{buttonLogin}</button>
          {loading ? <Spin /> : ""}
        </div>

        <div className="loginPage__button form__button loginPage__facebook">
          <FacebookLogin
            appId="360635536260175"
            // autoLoad={true}
            fields="name,email,picture"
            callback={responseFacebook}
            cssClass=""
            icon="fa-facebook"
            textButton={buttonLoginFacebook}
          />
          {loadingFacebook ? <Spin /> : ""}
        </div>

        {!isEmpty(errorMessage) && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={{ __html: sanitize(errorMessage) }}
          />
        )}
      </form>
    </div>
  )
}
export default Login
